<template>
    <div class="content-component" 
    :class="[component.type, {'component-active': isActive}]"
      :style="{order : component.order}" @click="updateCurrentComponent">
       <ckeditor :editor="editor" :placeholder="$t('textPlaceHolder')"
          v-model="content" :config="editorConfig"></ckeditor>
        <span class="legend centered">{{$t(component.text)}}</span>
        <button class="btn secondary delete"  @click="deleteComponent(component)">{{$t('delete')}}</button>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'

export default {
    name: 'TextContentComponent', 
    mixins:[BackOfficeComponentMixin], 
    computed:{
        content:{
            get(){
                return this.component.content[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'content', this.locale)
            }
        },
    }
}
</script>



<template>
    <div class="content-component row" :class="[component.type,{'component-active': isActive}]"
      :style="{order : component.order}" @click="updateCurrentComponent">
        <div   class="image-section column">
            <image-upload v-if="image" :component="image" class="image-displayed" :remove="removeImageDetails" :options="options"/>
            <image-upload v-else  :component="component" :add="newImageDetails" :options="options"/>
        </div>
        <section class="column">
            <input type="text" class="title"  v-model="title" :placeholder="$t('titlePlaceHolder')">
            <ckeditor :editor="editor" :placeholder="$t('textPlaceHolder')"
                v-model="content" :config="editorConfig"></ckeditor>
        </section>
        <span class="legend centered">{{$t(component.text)}}</span>
        <button class="btn secondary delete"  @click="deleteComponent(component)">{{$t('delete')}}</button>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'

export default {
    name: 'CardComponent', 
    mixins:[BackOfficeComponentMixin], 
    computed:{
        title:{
            get(){
                return this.component.title[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'title', this.locale)
            }
        },
        content:{
            get(){
                return this.component.content[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'content', this.locale)
            }
        }
    }
}
</script>


<template>
    <div class="content-component row" 
    :class="[component.type, {'component-active': isActive}]"
      :style="{order : component.order}" @click="updateCurrentComponent">

        <div class="column">
            <input type="text" class="title" v-model="title" :placeholder="$t('titlePlaceHolder')">
            <video-url-setter :component="component" :options="urlComponentProperty" />
        </div>
        <video-player v-if="isVideoUrlValid" :key="videoUrl[locale]"
            :thumbnail="thumbnail" :url="videoUrl[locale]" :width="168" :height="105" />
        <span class="legend centered">{{$t(component.text)}}</span>
        <button class="btn secondary delete"  @click="deleteComponent(component)">{{$t('delete')}}</button>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
import VideoUrlSetter from '@/components/BackOffice/ComponentSetters/VideoUrlSetter.vue'
import VideoPlayer from '@/common/VideoPlayer.vue'
export default {
    name: 'VideoContentComponent', 
    mixins:[BackOfficeComponentMixin], 
    components:{
        VideoPlayer, 
        VideoUrlSetter
    },
    computed:{
        videoUrl:{
            get(){
                return this.component.videoUrl
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'videoUrl')
            }
        },
        title:{
            get(){
                return this.component.title[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'title', this.locale)
            }
        },
        urlComponentProperty(){
            return this.component.setters.find(e => e.name === 'video-url-setter')
        },
        isVideoUrlValid(){
            return this.component.errors.length > 0 ? false : true
        }, 
        thumbnail(){
            let image = this.component.images[0] 
            return image ? image.imageId : ''
        }
    }
}
</script>
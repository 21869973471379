<template>
    <div class="content-component row" :class="[component.type, {'component-active': isActive}]"
      :style="objectStyles" @click="updateCurrentComponent">
        <section class="images-section column"  :class="component.imagesOrientation">
            <div  v-for="(image, index) in component.images" :key="index" class="image-section">
                <image-upload :component="image" class="image-displayed" :remove="removeImageDetails"
                     :options="options"/>
            </div>
            <div  v-if="component.images.length < 1" class="image-section column">
                <image-upload :component="{imageId:''}" :add="newImageDetails" :options="options"/>
            </div>
        </section>
         <ckeditor :editor="editor" :placeholder="$t('textPlaceHolder')"
          v-model="content" :config="editorConfig"></ckeditor>
          
        <span class="legend centered">{{$t(component.text)}}</span>
        <button class="btn secondary delete"  @click="deleteComponent(component)">{{$t('delete')}}</button>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'

export default {
    name: 'ParagraphComponent', 
    mixins:[BackOfficeComponentMixin], 
    computed:{
        content:{
            get(){
                return this.component.content[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'content', this.locale)
            }
        },
        objectStyles(){
            return {
                order : this.component.order
            }
        }
        
    }
}
</script>


